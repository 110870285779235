import "sanitize.css/assets.css";
import "sanitize.css/reduce-motion.css";
import "sanitize.css/sanitize.css";
import "sanitize.css/system-ui.css";
import "./App.css";
import { once, random } from "lodash";
import eggUrl from "./egg.png";
import turkeyUrl from "./turkey.png";
import wineUrl from "./wine.png";
import wineCorksUrl from "./wine-bottles-corks.png";
import turkeyLeftProfile from "./turkey-head-profile-left.png";
import turkeyRightProfile from "./turkey-head-profile-right.png";
import cornucopia from "./cornucopia-3.png";

import p5 from "p5";
import { useCallback, useLayoutEffect, useRef } from "react";

const FRAMES_PER_SECOND = 30;
let width: number;
let height: number;
let hasInteracted = false;

function easeInCubic(x: number): number {
  return x * x * x;
}

let eggImage: p5.Image;
let turkeyImage: p5.Image;
let wineImage: p5.Image;
let wineCorksImage: p5.Image;
let turkeyLeftProfileImage: p5.Image;
let turkeyRightProfileImage: p5.Image;
let cornucopiaImage: p5.Image;

class Turkey {
  p5: p5;
  x: number;
  y: number;
  size: number;
  startingFrame: number;

  constructor({ p5 }: { p5: p5 }) {
    this.p5 = p5;
    this.x = p5.mouseX;
    this.y = p5.mouseY;
    this.size = random(0.65, 1.1);
    this.startingFrame = p5.frameCount;
  }

  render = () => {
    this.egg();
    this.wings();
    this.cornucopia();
    this.hatchling();
    this.wine();
  };

  eggWidth = () => Math.min(width, eggImage.width) * this.size;

  eggHeight = () => (this.eggWidth() * eggImage.height) / eggImage.width;

  egg = () => {
    const { p5, x, y, startingFrame } = this;

    const frame = p5.frameCount - startingFrame;
    const drawEgg = (percentage: number) => {
      p5.image(
        eggImage,
        x,
        y,
        this.eggWidth() * easeInCubic(percentage),
        this.eggHeight() * easeInCubic(percentage)
      );
    };

    const basePercentage = 0.9;

    drawEgg(basePercentage);

    if (frame > FRAMES_PER_SECOND * 0.1) drawEgg(basePercentage * 0.9);
    if (frame > FRAMES_PER_SECOND * 0.2) drawEgg(basePercentage * 0.8);
    if (frame > FRAMES_PER_SECOND * 0.3) drawEgg(basePercentage * 0.7);
    if (frame > FRAMES_PER_SECOND * 0.4) drawEgg(basePercentage * 0.6);
    if (frame > FRAMES_PER_SECOND * 0.5) drawEgg(basePercentage * 0.5);
    if (frame > FRAMES_PER_SECOND * 0.6) drawEgg(basePercentage * 0.4);
    if (frame > FRAMES_PER_SECOND * 0.7) drawEgg(basePercentage * 0.3);
    if (frame > FRAMES_PER_SECOND * 0.8) drawEgg(basePercentage * 0.2);
    if (frame > FRAMES_PER_SECOND * 0.9) drawEgg(basePercentage * 0.1);
    if (frame > FRAMES_PER_SECOND) drawEgg(basePercentage * 0.03);
  };

  hatchling = () => {
    const { p5, x, y, startingFrame } = this;
    const frame = p5.frameCount - startingFrame;
    const drawTurkey = (percentage: number) => {
      p5.image(
        turkeyImage,
        x,
        y,
        this.eggWidth() * easeInCubic(percentage),
        (this.eggWidth() / this.eggHeight()) *
          turkeyImage.height *
          easeInCubic(percentage)
      );
    };
    const basePercentage = 0.9;

    drawTurkey(basePercentage * 0.03);

    if (frame > FRAMES_PER_SECOND + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 1);
    if (frame > FRAMES_PER_SECOND * 0.9 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.9);
    if (frame > FRAMES_PER_SECOND * 0.8 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.8);
    if (frame > FRAMES_PER_SECOND * 0.7 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.7);
    if (frame > FRAMES_PER_SECOND * 0.6 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.6);
    if (frame > FRAMES_PER_SECOND * 0.5 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.5);
    if (frame > FRAMES_PER_SECOND * 0.4 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.4);
    if (frame > FRAMES_PER_SECOND * 0.3 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.3);
    if (frame > FRAMES_PER_SECOND * 0.2 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.2);
    if (frame > FRAMES_PER_SECOND * 0.1 + FRAMES_PER_SECOND)
      drawTurkey(basePercentage * 0.1);
  };

  wine = () => {
    const { p5, x, y, startingFrame } = this;
    const frame = p5.frameCount - startingFrame;
    const offsetInFrames = FRAMES_PER_SECOND * 2;
    const basePercentage = 0.3;

    const drawLeftWine = (percentage: number) => {
      p5.image(
        wineImage,
        x - this.eggWidth() * 0.15,
        y + this.eggHeight() * 0.15,
        20 * wineImage.width * easeInCubic(percentage),
        20 * wineImage.height * easeInCubic(percentage)
      );
    };
    const drawRightWine = (percentage: number) => {
      p5.image(
        wineImage,
        x + this.eggWidth() * 0.15,
        y + this.eggHeight() * 0.15,
        20 * wineImage.width * easeInCubic(percentage),
        20 * wineImage.height * easeInCubic(percentage)
      );
    };

    const drawWine = (percentage: number) => {
      drawLeftWine(percentage);
      drawRightWine(percentage);
    };

    if (frame > FRAMES_PER_SECOND * 0.1 + offsetInFrames)
      drawWine(basePercentage * 0.9);
    if (frame > FRAMES_PER_SECOND * 0.2 + offsetInFrames)
      drawWine(basePercentage * 0.8);
    if (frame > FRAMES_PER_SECOND * 0.3 + offsetInFrames)
      drawWine(basePercentage * 0.7);
    if (frame > FRAMES_PER_SECOND * 0.4 + offsetInFrames)
      drawWine(basePercentage * 0.6);
    if (frame > FRAMES_PER_SECOND * 0.5 + offsetInFrames)
      drawWine(basePercentage * 0.5);
    if (frame > FRAMES_PER_SECOND * 0.6 + offsetInFrames)
      drawWine(basePercentage * 0.4);
    if (frame > FRAMES_PER_SECOND * 0.7 + offsetInFrames)
      drawWine(basePercentage * 0.3);
    if (frame > FRAMES_PER_SECOND * 0.8 + offsetInFrames)
      drawWine(basePercentage * 0.2);
    if (frame > FRAMES_PER_SECOND * 0.9 + offsetInFrames)
      drawWine(basePercentage * 0.1);
    if (frame > FRAMES_PER_SECOND + offsetInFrames)
      drawWine(basePercentage * 0.03);
  };

  wings = () => {
    const { p5, x, y, startingFrame } = this;
    const frame = p5.frameCount - startingFrame;
    const offsetInFrames = FRAMES_PER_SECOND * 2;
    const basePercentage = 0.9;

    const drawLeftWing = (percentage: number) => {
      p5.image(
        turkeyLeftProfileImage,
        x - this.eggWidth() * 0.35,
        y - this.eggHeight() * 0.05,
        this.eggWidth() * easeInCubic(percentage),
        (this.eggWidth() / this.eggHeight()) *
          turkeyLeftProfileImage.height *
          easeInCubic(percentage)
      );
    };

    const drawRightWing = (percentage: number) => {
      p5.image(
        turkeyRightProfileImage,
        x + this.eggWidth() * 0.35,
        y - this.eggHeight() * 0.05,
        this.eggWidth() * easeInCubic(percentage),
        (this.eggWidth() / this.eggHeight()) *
          turkeyLeftProfileImage.height *
          easeInCubic(percentage)
      );
    };

    const drawWings = (percentage: number) => {
      drawLeftWing(percentage);
      drawRightWing(percentage);
    };

    drawWings(basePercentage * 0.03);

    if (frame > FRAMES_PER_SECOND + offsetInFrames)
      drawWings(basePercentage * 1);
    if (frame > FRAMES_PER_SECOND * 0.9 + offsetInFrames)
      drawWings(basePercentage * 0.9);
    if (frame > FRAMES_PER_SECOND * 0.8 + offsetInFrames)
      drawWings(basePercentage * 0.8);
    if (frame > FRAMES_PER_SECOND * 0.7 + offsetInFrames)
      drawWings(basePercentage * 0.7);
    if (frame > FRAMES_PER_SECOND * 0.6 + offsetInFrames)
      drawWings(basePercentage * 0.6);
    if (frame > FRAMES_PER_SECOND * 0.5 + offsetInFrames)
      drawWings(basePercentage * 0.5);
    if (frame > FRAMES_PER_SECOND * 0.4 + offsetInFrames)
      drawWings(basePercentage * 0.4);
    if (frame > FRAMES_PER_SECOND * 0.3 + offsetInFrames)
      drawWings(basePercentage * 0.3);
    if (frame > FRAMES_PER_SECOND * 0.2 + offsetInFrames)
      drawWings(basePercentage * 0.2);
    if (frame > FRAMES_PER_SECOND * 0.1 + offsetInFrames)
      drawWings(basePercentage * 0.1);
  };

  cornucopia = () => {
    const { p5, x, y, startingFrame } = this;
    const frame = p5.frameCount - startingFrame;
    const offsetInFrames = FRAMES_PER_SECOND * 2;
    const basePercentage = 0.75;

    const drawCornucopia = (percentage: number) => {
      const width = this.eggWidth() * easeInCubic(percentage);
      const height = (width * cornucopiaImage.height) / cornucopiaImage.width;

      p5.image(
        cornucopiaImage,
        x + width * 0.04,
        y - this.eggHeight() * 0.15, //- height * 0.05,
        width,
        height
      );
    };

    if (frame > FRAMES_PER_SECOND + offsetInFrames)
      drawCornucopia(basePercentage * 1);
    if (frame > FRAMES_PER_SECOND * 0.9 + offsetInFrames)
      drawCornucopia(basePercentage * 0.9);
    if (frame > FRAMES_PER_SECOND * 0.8 + offsetInFrames)
      drawCornucopia(basePercentage * 0.8);
    if (frame > FRAMES_PER_SECOND * 0.7 + offsetInFrames)
      drawCornucopia(basePercentage * 0.7);
    if (frame > FRAMES_PER_SECOND * 0.6 + offsetInFrames)
      drawCornucopia(basePercentage * 0.6);
    if (frame > FRAMES_PER_SECOND * 0.5 + offsetInFrames)
      drawCornucopia(basePercentage * 0.5);
    if (frame > FRAMES_PER_SECOND * 0.4 + offsetInFrames)
      drawCornucopia(basePercentage * 0.4);
    if (frame > FRAMES_PER_SECOND * 0.3 + offsetInFrames)
      drawCornucopia(basePercentage * 0.3);
    if (frame > FRAMES_PER_SECOND * 0.2 + offsetInFrames)
      drawCornucopia(basePercentage * 0.2);
    if (frame > FRAMES_PER_SECOND * 0.1 + offsetInFrames)
      drawCornucopia(basePercentage * 0.1);

    drawCornucopia(basePercentage * 0.03);
  };
}

interface SketchClosureParams {
  preload?: (p5: p5) => void;
  setup?: (p5: p5, div: HTMLDivElement) => void;
  draw?: (p5: p5) => void;
  windowResized?: (p5: p5) => void;
  mouseClicked?: (p5: p5) => void;
}

const createSketch = (
  div: HTMLDivElement,
  { preload, setup, draw, windowResized, mouseClicked }: SketchClosureParams
): p5 => {
  return new p5((p) => {
    p.disableFriendlyErrors = process.env.NODE_ENV === "production";

    const wrap = (callback: Function | undefined): Function | undefined => {
      return callback ? () => callback(p) : undefined;
    };

    p.preload = wrap(preload ? once(preload) : undefined);
    p.setup = setup ? once(() => setup(p, div)) : undefined;
    p.draw = wrap(draw);
    p.windowResized = wrap(windowResized);
    p.mouseClicked = wrap(mouseClicked);
  }, div);
};

const useSketch = (options: SketchClosureParams) => {
  const canvasRef = useRef<HTMLDivElement | null>(null);

  const createSketchOnceCallback = useCallback(
    once(() => {
      if (canvasRef.current) createSketch(canvasRef.current, options);
    }),
    []
  );
  useLayoutEffect(createSketchOnceCallback, [createSketchOnceCallback]);

  return canvasRef;
};

let turkeys: Turkey[] = [];

const sketchOptions: SketchClosureParams = {
  preload: (p5) => {
    eggImage = p5.loadImage(eggUrl);
    turkeyImage = p5.loadImage(turkeyUrl);
    wineImage = p5.loadImage(wineUrl);
    wineCorksImage = p5.loadImage(wineCorksUrl);
    turkeyLeftProfileImage = p5.loadImage(turkeyLeftProfile);
    turkeyRightProfileImage = p5.loadImage(turkeyRightProfile);
    cornucopiaImage = p5.loadImage(cornucopia);
  },
  setup: (p5, div) => {
    const rect = document.body.getBoundingClientRect();
    width = rect.width;
    height = rect.height;
    p5.imageMode(p5.CENTER);
    p5.createCanvas(width, height).parent(div);
    p5.frameRate(FRAMES_PER_SECOND);
    p5.image(wineCorksImage, width / 2, height / 2, width, height);
  },
  windowResized: (p5) => {
    p5.imageMode(p5.CENTER);
    const rect = document.body.getBoundingClientRect();
    width = rect.width;
    height = rect.height;
    p5.resizeCanvas(width, height);
    p5.image(wineCorksImage, width / 2, height / 2, width, height);
    turkeys.forEach((turkey) => {
      if (turkey.x < width && turkey.y < height) return;
      else {
        const x = turkey.x;
        const y = turkey.y;
        turkey.x = y;
        turkey.y = x;
      }
    });
  },
  mouseClicked: (p5) => {
    hasInteracted = true;
    turkeys.push(new Turkey({ p5 }));
  },
  draw: (p5: p5) => {
    p5.imageMode(p5.CENTER);
    turkeys.forEach((turkey) => turkey.render());

    if (!hasInteracted && p5.frameCount > FRAMES_PER_SECOND * 4) {
      hasInteracted = true;
      alert("Tap or click anywhere to begin");
    }
  },
};

function App() {
  const sketchRef = useSketch(sketchOptions);
  return <div className="App" ref={sketchRef} />;
}

export default App;
